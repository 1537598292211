<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { apiRequest } from "@/helpers/api-call";
//import router from "@/router/index.js";
import Vue from "vue";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Transactions sur RMobility",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "Synthèse",
          active: true,
        },
      ],
      idUser: 0,
      dataUser: [],
      dataUserD: [],
      nbreUser: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      syntheseAuto: false,
      syntheseManuelle: false,
      ordersData: [],
      fields: [
        { key: "marque", sortable: true, label: "Marque" },
        { key: "model", sortable: true, label: "Modèle" },
        { key: "immatri", label: "Immat." },
        { key: "type", sortable: true, label: "Type" },
        { key: "statut", sortable: true, label: "Statut" },
        { key: "motorisation", sortable: true, label: "Moteur" },
        { key: "vidange", sortable: true, label: "Km av. vidange" },
        { key: "date", sortable: true, label: "Créé le" },
        { key: "action", label: "Actions" },
      ],
      synthese_Auto: false,
      synthese_Manuelle: false,
      totReparation: 0,
      totVidange: 0,
      totRecetteLocation: 0,
      totDepense: 0,
      totReparationV: 0,
      totVidangeV: 0,
      totRecetteLocationV: 0,
      totDepenseV: 0,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.dataUser.length;
    },
  },
  async mounted() {
    this.init();
  },
  methods: {
    async init() {
      //Chargement des données
      const dataC = await apiRequest(
        "GET",
        "admin/entreprise/transaction",
        undefined,
        false
      );
      if (dataC && dataC.data) {
        var donnees = dataC.data;
        this.totReparation = donnees.totReparation;
        this.totVidange = donnees.totVidange;
        this.totRecetteLocation = donnees.totRecetteLocation;
        this.totDepense = donnees.totDepense;
      }

      const data = await apiRequest(
        "GET",
        "admin/entreprise/location",
        undefined,
        false
      );
      if (data && data.data && data.data.vehiculeActif) {
        const formattedTable = data.data.vehiculeActif.map((vehicule) => {
          return {
            id: vehicule.id,
            model: vehicule.model,
            marque: vehicule.marque,
            immatri: vehicule.immatriculation,
            type: vehicule.types,
            statut: vehicule.statut,
            motorisation: vehicule.motorisation,
            vidange: vehicule.kmAvantVidange,
            date: new Date(vehicule.dateCreation).toLocaleDateString("fr-FR"),
          };
        });
        this.ordersData = formattedTable;
      }

      this.totalRows = this.ordersData.length;
      this.nbreUser = this.dataUser.length;
    },
    async syntheseAuto_method(row) {
      const dataC = await apiRequest(
        "GET",
        "admin/entreprise/transaction-detail?idVehicule=" + row.id,
        undefined,
        false
      );
      if (dataC && dataC.data) {
        var donnees = dataC.data;
        this.totReparationV = donnees.totReparation;
        this.totVidangeV = donnees.totVidange;
        this.totRecetteLocationV = donnees.totRecetteLocation;
        this.totDepenseV = donnees.totDepense;
      }
      this.synthese_Auto = true;
    },
    syntheseManuelle_method(row) {
      this.synthese_Manuelle = true;
      this.infoUser.id = row.id;
    },
    position() {
      Vue.swal({
        position: "center-end",
        icon: "success",
        title: "Mise à jour effectuée",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    goToDetail(row) {
      console.log("row:", row);
      //router.push(`/vehicule/details/${row.id}`);
      //router.push('/user/user_info/'+row.id)
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <b-card
                  header-class="bg-transparent border-primary"
                  class="border border-primary"
                >
                  <template v-slot:header>
                    <h5 class="my-0 mb-5">
                      Recette totale des locations
                    </h5>
                  </template>
                  <div class=" float-right">
                    <h1 class="text-danger">{{ totRecetteLocation }} FCFA</h1>
                  </div>
                </b-card>
              </div>
              <div class="col-md-6">
                <b-card
                  header-class="bg-transparent border-primary"
                  class="border border-primary"
                >
                  <template v-slot:header>
                    <h5 class="my-0 mb-5">
                      Dépenses totales des réparations
                    </h5>
                  </template>
                  <div class=" float-right">
                    <h1 class="text-danger">{{ totReparation }} FCFA</h1>
                  </div>
                </b-card>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <b-card
                  header-class="bg-transparent border-primary"
                  class="border border-primary"
                >
                  <template v-slot:header>
                    <h5 class="">
                      Dépenses totales des vidanges
                    </h5>
                  </template>
                  <div class=" float-right">
                    <h1 class="text-primary">{{ totVidange }} FCFA</h1>
                  </div>
                </b-card>
              </div>
              <div class="col-md-6">
                <b-card
                  header-class="bg-transparent border-primary"
                  class="border border-primary"
                >
                  <template v-slot:header>
                    <h5 class="">
                      Total des dépenses
                    </h5>
                  </template>
                  <div class=" float-right">
                    <h1 class="text-primary">{{ totDepense }} FCFA</h1>
                  </div>
                </b-card>
              </div>
            </div>
            <!--<div class="row">
            <div class="col-md-6">
              <b-card
                header-class="bg-transparent border-primary"
                class="border border-primary"
              >
                <template v-slot:header>
                  <h5 class="">
                    Coût actuel du véhicule
                  </h5>
                </template>
                <div class=" float-right">
                  <h1 class="text-primary">1 500 000 FCFA</h1>
                </div>
              </b-card>
            </div>
            <div class="col-md-6">
              <b-card
                header-class="bg-transparent border-primary"
                class="border border-primary"
              >
                <template v-slot:header>
                  <h5 class="">
                    Amortissement
                  </h5>
                </template>
                <div class=" float-right">
                  <h1 class="text-primary">1 500 000 FCFA</h1>
                </div>
              </b-card>
            </div>
          </div>-->
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active"></a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <!-- <label class="d-inline-flex align-items-center">
                        Choisir
                    <multiselect
                    v-model="value1"
                    :options="options"
                    :multiple="true"
                    :searchable="false" class="col-md-12"
                    ></multiselect>
                    </label> -->
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-3">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor: pointer"
                    class="table-centered"
                    :items="ordersData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="goToDetail"
                  >
                    <template v-slot:cell(badge)="row">
                      <div
                        class="py-2 d-flex justify-content-center"
                        :class="{
                          'fa fa-star': `${row.value}` === 'false',
                          'fa fa-star badge-soft-warning':
                            `${row.value}` === 'true',
                        }"
                      ></div>
                    </template>
                    <template v-slot:cell(statut)="row">
                      <div
                        class="py-2 d-flex justify-content-center badge font-size-12"
                        :class="{
                          'badge-soft-danger': `${row.value}` === 'Suspendu',
                          'badge-soft-success': `${row.value}` === 'Actif',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <!--  <a
                        href="javascript:void(0);"
                        class="text-primary"
                        v-b-tooltip.hover
                        @click="editClick(row.item)"
                        title="Editer"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        @click="deleteUser(row.item)"
                        title="Supprimer"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-success"
                        v-b-tooltip.hover
                        @click="desactiverUser(row.item)"
                        title="désactiver"
                      >
                        <i class="mdi mdi-close font-size-18"></i>
                      </a> -->
                      <button
                        class="btn btn-primary"
                        @click="syntheseAuto_method(row.item)"
                      >
                        Détails
                      </button>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <!-- SYNTHESE AUTOMATIQUE -->
    <b-modal
      id="modal-center"
      centered
      size="xl"
      v-model="synthese_Auto"
      title="TRANSACTIONS SUR RMOBILITY"
      title-class="font-18"
      hide-footer
    >
      <div class="row">
        <div class="col-md-6">
          <b-card
            header-class="bg-transparent border-primary"
            class="border border-primary"
          >
            <template v-slot:header>
              <h5 class="my-0 mb-5">
                Recette totales des locations
              </h5>
            </template>
            <div class=" float-right">
              <h1 class="text-danger">{{ totRecetteLocationV }} FCFA</h1>
            </div>
          </b-card>
        </div>
        <div class="col-md-6">
          <b-card
            header-class="bg-transparent border-primary"
            class="border border-primary"
          >
            <template v-slot:header>
              <h5 class="my-0 mb-5">
                Dépenses totales des réparations
              </h5>
            </template>
            <div class=" float-right">
              <h1 class="text-danger">{{ totReparationV }}  FCFA</h1>
            </div>
          </b-card>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <b-card
            header-class="bg-transparent border-primary"
            class="border border-primary"
          >
            <template v-slot:header>
              <h5 class="">
                Dépenses totales des vidanges
              </h5>
            </template>
            <div class=" float-right">
              <h1 class="text-primary">{{ totVidangeV }} FCFA</h1>
            </div>
          </b-card>
        </div>
        <div class="col-md-6">
          <b-card
            header-class="bg-transparent border-primary"
            class="border border-primary"
          >
            <template v-slot:header>
              <h5 class="">
                Total des dépenses
              </h5>
            </template>
            <div class=" float-right">
              <h1 class="text-primary">{{ totDepenseV }} FCFA</h1>
            </div>
          </b-card>
        </div>
      </div>
      <!--<div class="row">
        <div class="col-md-12">
          <b-card
            header-class="bg-transparent border-primary"
            class="border border-primary"
          >
            <template v-slot:header>
              <h5 class="">
                Coût actuel du véhicule
              </h5>
            </template>
            <div class=" float-right">
              <h1 class="text-primary">1 500 000 FCFA</h1>
            </div>
          </b-card>
        </div>
      </div>-->
    </b-modal>
    <!-- FIN SYNTHESE AUTOMATIQUE -->
  </Layout>
</template>
